<template>
    <div class="graphic-container">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb-student">
            <el-breadcrumb-item>创作中心</el-breadcrumb-item>
            <el-breadcrumb-item>图文创作</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="tab-wrapper">
            <router-link to="/student/graphic/index" class="tab-item">图文管理</router-link>
            <router-link to="/student/graphic/creation" class="tab-item current">新增图文</router-link>
        </div>
        <div class="graphic-box">
            <div class="graphic-wrapper">
                <div class="caption-wrapper draft-wrapper" v-if="showDraftBox">
                    <div class="draft-top">
                        <span class="text-overflow">您的草稿箱共{{draftList.length}}篇文章</span>
                        <el-button type="text" icon="el-icon-close" @click="closeDraftPrompt"></el-button>
                    </div>
                    <div v-if="draftList.length === 0" style="text-align: center; padding: 20px; color: #999">暂无数据，请先添加草稿</div>
                    <el-scrollbar class="draft-list" :native="false" v-else>
                        <div class="draft-item" v-for="(draftItem, draftIndex) in draftList" :key="`draftList_${draftIndex}`">
                            <p class="title text-overflow-2">{{draftItem.title}}</p>
                            <p class="content text-overflow-2" v-html="draftItem.content"></p>
                            <div class="btn-group">
                                <el-button size="small" class="blue-btn" @click="importOne(draftItem)">一键导入</el-button>
                                <el-button size="small" type="danger" @click="delDraft(draftItem.id)">删除</el-button>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="caption-wrapper" v-else>
                    <div class="caption-tabs">
<!--                        <div class="tab-item" :class="{current: captionTabCurrent === 0}" @click="toggleCapTabs(0)">标题生成</div>-->
                        <div class="tab-item" :class="{current: captionTabCurrent === 1}" @click="toggleCapTabs(1)">热门标题</div>
                    </div>
                    <div class="caption-item" :class="{current: captionTabCurrent === 0}">
                        <el-form :model="keysForm" :rules="keysRules" ref="keysForm" label-width="80px" class="keys-form" size="small">
                            <el-form-item label="关键词一" prop="keyOne">
                                <el-input v-model="keysForm.keyOne"></el-input>
                            </el-form-item>
                            <el-form-item label="关键词二" prop="keyTwo">
                                <el-input v-model="keysForm.keyTwo"></el-input>
                            </el-form-item>
                            <el-form-item label="关键词三" prop="keyThree">
                                <el-input v-model="keysForm.keyThree"></el-input>
                            </el-form-item>
                            <div class="prompt-info">
                                温馨提示：建议关键词数2 - 3个最佳，关键词越多，生成的标题量越少，但越符合您的要求
                            </div>
                            <el-form-item>
                                <el-button class="blue-btn" @click="randomlySubmit('keysForm')">随机生成</el-button>
                                <el-button @click="randomlyReset('keysForm')">重置</el-button>
                            </el-form-item>
                        </el-form>
                        <el-scrollbar class="caption-list" :native="false">
                            <div class="item" v-for="(captionItem, captionIndex) in captionList" :key="`captionList_${captionIndex}`">
                                <a href="" :title="captionItem.caption" target="_blank" class="caption text-overflow-2">{{captionItem.caption}}</a>
                                <div class="caption-btn">
                                    <el-button class="blue-btn" size="small" @click="useCreate(captionItem)">一键使用</el-button>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                    <div class="caption-item" :class="{current: captionTabCurrent === 1}">
                        <div class="hot-caption">
                            <div class="hot-caption-search">
                                <span class="text">搜索:</span>
                                <el-input v-model="captionSearch" size="small" placeholder="例：新冠肺炎" @keydown.enter.native="searchHotCaption">
                                    <i class="el-icon-search el-input__icon search-btn" slot="suffix" @click="searchHotCaption"></i>
                                </el-input>
                            </div>
                            <div class="hot-cap">热门标题:</div>
                        </div>
                        <div v-if="hotCaptionList.length === 0" style="text-align: center; padding: 20px; color: #999">暂无数据，请输入搜索内容</div>
                        <el-scrollbar class="caption-list" :native="false">
                            <div class="item" v-for="(hotCaptionItem, hotCaptionIndex) in hotCaptionList" :key="`hotCaptionList_${hotCaptionIndex}`">
                                <a :href="hotCaptionItem.art_url" :title="hotCaptionItem.art_title" target="_blank" class="caption text-overflow-2"><span class="search-text-ellipsis" v-html="hotCaptionItem.art_title"></span></a>
                                <div class="caption-btn">
                                    <el-button size="small" class="blue-btn" @click="useCreate(hotCaptionItem)">一键使用</el-button>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
                <div class="publish-wrapper">
                    <div class="create-title">
                        <span class="text">标题:</span>
                        <el-input type="text" placeholder="请输入文章标题，不超过80个字符" @input="changeTitle" size="small" v-model="createTitle"></el-input>
                    </div>
                    <div id="article_content"></div>
                </div>
            </div>
            <div class="operate-btn">
                <div class="operate-item" @click="resetGraphic">
                    <i class="iconfont">&#xe74b;</i>清空全文
                </div>
                <el-popover v-model="showDraftPopover" placement="right" popper-class="draftPopover" trigger="click" @show="showDraftCurrent" @hide="hideDraftCurrent">
                    <div class="draft-box">
                        <div class="draft-btn" :class="{current: seeDraftCurrent}" @click="seeDraft">查看草稿箱</div>
                        <div class="draft-btn" @click="submitGraphic">存入草稿箱</div>
                    </div>
                    <div class="operate-item" :class="{current: draftBtnCurrent}" slot="reference">
                        <i class="iconfont">&#xe791;</i>草稿箱
                    </div>
                </el-popover>
                <el-popover v-model="showCorrectPopover" placement="right" popper-class="correctPopover" trigger="click" @show="showCorrectCurrent" @hide="hideCorrectCurrent">
                    <div class="correct-box">
                        <div class="correct-title">
                            文章纠错<el-button type="text" icon="el-icon-close" @click="closeCorrectPopover"></el-button>
                        </div>
                        <div class="correct-content" v-html="correctContent"></div>
                        <div class="correct-bottom">
                            <span v-if="!correctNum">该文本中未发现有错误字</span>
                            <span v-else>该文本中有<strong>{{correctNum}}</strong>处错别字，已高亮显示</span>
                        </div>
                    </div>
                    <div class="operate-item" :class="{current: correctBtnCurrent}" @click="articleCorrect" slot="reference">
                        <i class="iconfont">&#xe754;</i>文章纠错
                    </div>
                </el-popover>
                <div class="operate-item" @click="qualityAnalysis">
                    <i class="iconfont">&#xe630;</i>质量分析
                </div>
                <div class="operate-item" @click="previewArticle">
                    <i class="iconfont">&#xe62e;</i>文章预览
                </div>
<!--                @show="showPublishBtn"-->
                <el-popover v-model="showPublishPopover" placement="right" popper-class="draftPopover" trigger="click">
                    <div class="draft-box">
                        <div class="draft-btn publish-btn" @click="publishBtn('bjh')">
                            <img src="@/assets/img/image/baijiahao.png" alt="">百家号
                        </div>
                        <div class="draft-btn publish-btn">
                            <img src="@/assets/img/image/publish_blibli.png" alt="">哔哩哔哩
                        </div>
                        <div class="draft-btn publish-btn">
                            <img src="@/assets/img/image/publish_jinri.png" alt="">今日头条
                        </div>
                        <div class="draft-btn publish-btn">
                            <img src="@/assets/img/image/publish_weibo.png" alt="">微博
                        </div>
                        <div class="draft-btn publish-btn">
                            <img src="@/assets/img/image/publish_qq.png" alt="">企鹅看点
                        </div>
                    </div>
                    <div class="operate-item" slot="reference">
                        <i class="iconfont">&#xe620;</i>发布作品
                    </div>
                </el-popover>
                <div class="operate-item" @click="copyArticle($event)">
                    <i class="iconfont">&#xe621;</i>复制全文
                </div>
            </div>
    <!--        :style="hotSearchStyle"-->
            <div class="hot-search-wrapper" ref="hotSearch">
                <div class="hot-tab-wrapper">
                    <i class="iconfont left-icon" :class="{current: hotIconCurrent}" @click="toggleHotIcon(hotIconCurrent, hotTabCurrent)">&#xe74d;</i>
                    <div class="hot-tabs" :class="{current: hotTabCurrent === 1}" @click="toggleHotTab(1)">百度热搜</div>
                    <div class="hot-tabs" :class="{current: hotTabCurrent === 2}" @click="toggleHotTab(2)">热点追踪</div>
                </div>
                <div class="hot-search-content" :class="{current: hotContentCurrent === 1}">
                    <div class="hot-search-prompt text-overflow">*根据百度热搜、微博热搜、自媒体热词，快速找到写作主题</div>
                    <div class="hot-search-list">
                        <div class="item item-top">
                            <div class="item-left">排名</div>
                            <div class="item-right">指数排名</div>
                        </div>
                        <div v-if="baiduHotList.length === 0" style="text-align: center; padding: 20px; color: #999">暂无数据</div>
                        <el-scrollbar class="list-wrapper" :native="false">
                            <div class="item" v-for="(baiduHotItem, baiduHotIndex) in baiduHotList" :key="`baiduHotList_${baiduHotIndex}`">
                                <div class="item-left">
                                    <span class="item-index">{{baiduHotItem.num}}</span>
                                    <a :href="baiduHotItem.url" target="_blank" class="item-cap text-overflow" :title="baiduHotItem.word">{{baiduHotItem.word}}</a>
                                </div>
                                <div class="item-right">
    <!--                                <span class="item-num">{{baiduHotItem.num}}</span>-->
    <!--                                <i class="iconfont up-icon" v-if="baiduHotItem.states === 1">&#xe631;</i>-->
    <!--                                <i class="iconfont down-icon" v-else>&#xe637;</i>-->
                                    <span class="item-num">{{baiduHotItem.num}}</span>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
                <div class="hot-search-content" :class="{current: hotContentCurrent === 2}">
                    <div class="hot-search-prompt text-overflow">*根据百度热搜、微博热搜、自媒体热词，快速找到写作主题</div>
                    <div class="hot-search-list">
                        <div class="item item-hotspot">
                            <el-input v-model="hotSpotTrackingInput" size="small" placeholder="请输入事件名：如新冠肺炎"></el-input>
                            <el-button type="primary" size="small" @click="searchHotSpot">搜索</el-button>
                        </div>
                        <div v-if="hotSpotList.length === 0" style="text-align: center; padding: 20px; color: #999">暂无数据，请先搜索事件</div>
                        <el-scrollbar class="list-wrapper" :native="false">
                            <div class="item" v-for="(hotSpotItem, hotSpotIndex) in hotSpotList" :key="`hotSpotList_${hotSpotIndex}`">
                                <div class="item-left">
                                    <span class="item-index">{{hotSpotItem.num}}</span>
                                    <a :href="hotSpotItem.url" target="_blank" class="item-cap text-overflow" :title="hotSpotItem.word">{{hotSpotItem.word}}</a>
                                </div>
                                <div class="item-right">
                                    <!-- <span class="item-num">{{hotSpotItem.num}}</span> -->
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="一键检测" :visible.sync="detectionDialog" width="35%" class="detection-dialog">
            <div class="detection-content">
                <div class="item">
                    <span class="item-left text-overflow" v-if="riskMonitor.state === null">正在进行风险检测</span>
                    <span class="item-left text-overflow" v-else>风险检测</span>
                    <span class="item-right" v-if="riskMonitor.state === null">
                        <img class="turn-round" src="@/assets/img/image/loading.png">检测中……
                    </span>
                    <span class="item-right" v-else>
                        <span v-if="riskMonitor.state === 1"><i class="iconfont green">&#xe88c;</i>未发现</span>
                        <span v-else-if="riskMonitor.state === 0"><i class="iconfont red">&#xe623;</i>有风险</span>
                    </span>
                </div>
                <div class="item">
                    <span class="item-left text-overflow" v-if="originalMonitor.status === null">正在进行原创检测</span>
                    <span class="item-left text-overflow" v-else>原创检测</span>
                    <span class="item-right" v-if="originalMonitor.status === null">
                        <img class="turn-round" src="@/assets/img/image/loading.png">检测中……
                    </span>
                    <span class="item-right" v-else>
                        <span v-if="originalMonitor.status === 1"><i class="iconfont green">&#xe88c;</i>检测完成</span>
<!--                        <span v-if="originalMonitor.status === 1"><i class="iconfont green">&#xe88c;</i>未发现</span>-->
<!--                        <span v-else-if="originalMonitor.status === 0"><i class="iconfont red">&#xe623;</i>有风险</span>-->
                    </span>
                </div>
                <div class="item">
                    <span class="item-left text-overflow" v-if="emotionMonitor.log_id === null">标题分析完成</span>
                    <span class="item-left text-overflow" v-else>标题分析完成</span>
                    <span class="item-right" v-if="emotionMonitor.log_id === null">
                        <img class="turn-round" src="@/assets/img/image/loading.png">检测中……
                    </span>
                    <span class="item-right" v-else>
                        <i class="iconfont green">&#xe60a;</i>完成
                    </span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="blue-btn" @click="toNextDetection">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="一键检测" :visible.sync="detectionDetailDialog" width="56%" class="detection-detail-dialog" @close="closeDetectionDetail">
            <div class="detection-detail-wrapper">
                <div class="detail-intro">
                    <div class="intro-item">
                        <span>标题：{{emotionMonitor.text}}</span>
                        <span>字符数：{{originalMonitor.text_num}}字</span>
<!--                        <span>图片：0张</span>-->
                    </div>
                    <div class="intro-item">
                        <span class="item-left">风险检测</span>
                        <span class="item-right" v-if="riskMonitor.state === 0">{{riskMonitor.msg}}</span>
                        <span class="item-right" v-else>检测完毕，暂未发现文章存在违规内容</span>
                    </div>
<!--                    <div class="intro-item">-->
<!--                        <span class="item-left">原创分值</span>-->
<!--                        <span class="item-right">综合：<strong>{{originalMonitor.score}}%</strong>百度：<strong>{{originalMonitor.baidu_score}}%</strong>360：<strong>{{originalMonitor.qihoo_score}}%</strong></span>-->
<!--                    </div>-->
                    <div class="intro-item">
                        <span class="item-left">标题分析</span>
                        <span class="item-right">情感描述：
                            <span class="blue" v-if="emotionMonitor.items.length > 0 && emotionMonitor.items[0].sentiment === 0">消极</span>
                            <span class="blue" v-else-if="emotionMonitor.items.length > 0 && emotionMonitor.items[0].sentiment === 1">中性</span>
                            <span class="blue" v-else-if="emotionMonitor.items.length > 0 && emotionMonitor.items[0].sentiment === 2">积极</span>
                            含有违禁词：
                            <span class="blue" v-if="riskMonitor.state === 0">有</span>
                            <span class="blue" v-else>无</span>
                        </span>
                    </div>
                    <div class="intro-item">
                        <span>文章标签 / 领域</span>
                        <span>领域：{{field}}</span>
                    </div>
                </div>
                <div class="monitor-tabs">
                    <div class="tab-item" :class="{current: monitorTab === 0}" @click="toggleMonitor(0)">风险检测</div>
                    <div class="tab-item" :class="{current: monitorTab === 1}" @click="toggleMonitor(1)">原创检测</div>
                    <div class="tab-item" :class="{current: monitorTab === 2}" @click="toggleMonitor(2)">标题分析</div>
                </div>
                <div class="monitor-content" :class="{current: monitorTab === 0}">
                    <div class="monitor-list">
                        <div class="item">
                            <span class="item-left">
                                <i class="iconfont green">&#xe60a;</i>检测文章内容是否存在低质灌水
                            </span>
                            <span class="item-right red" v-if="riskMonitor.error_code !== void 0 && riskMonitor.error_code.length > 0 && riskMonitor.error_code.includes(0)">发现</span>
                            <span class="item-right blue" v-else>未发现</span>
                        </div>
                        <div class="item">
                            <span class="item-left">
                                <i class="iconfont green">&#xe60a;</i>检测文章内容是否存在暴恐违禁
                            </span>
                            <span class="item-right red" v-if="riskMonitor.error_code !== void 0 && riskMonitor.error_code.length > 0 && riskMonitor.error_code.includes(1)">发现</span>
                            <span class="item-right blue" v-else>未发现</span>
                        </div>
                        <div class="item">
                            <span class="item-left">
                                <i class="iconfont green">&#xe60a;</i>检测文章内容是否包含文本色情
                            </span>
                            <span class="item-right red" v-if="riskMonitor.error_code !== void 0 && riskMonitor.error_code.length > 0 && riskMonitor.error_code.includes(2)">发现</span>
                            <span class="item-right blue" v-else>未发现</span>
                        </div>
                        <div class="item">
                            <span class="item-left">
                                <i class="iconfont green">&#xe60a;</i>检测文章内容是否包含政治敏感
                            </span>
                            <span class="item-right red" v-if="riskMonitor.error_code !== void 0 && riskMonitor.error_code.length > 0 && riskMonitor.error_code.includes(3)">发现</span>
                            <span class="item-right blue" v-else>未发现</span>
                        </div>
                        <div class="item">
                            <span class="item-left">
                                <i class="iconfont green">&#xe60a;</i>检测文章内容里否包含恶意推广
                            </span>
                            <span class="item-right red" v-if="riskMonitor.error_code !== void 0 && riskMonitor.error_code.length > 0 && riskMonitor.error_code.includes(4)">发现</span>
                            <span class="item-right blue" v-else>未发现</span>
                        </div>
                        <div class="item">
                            <span class="item-left">
                                <i class="iconfont green">&#xe60a;</i>检测文章内容是否存在低俗辱骂
                            </span>
                            <span class="item-right red" v-if="riskMonitor.error_code !== void 0 && riskMonitor.error_code.length > 0 && riskMonitor.error_code.includes(5)">发现</span>
                            <span class="item-right blue" v-else>未发现</span>
                        </div>
                        <div class="item">
                            <span class="item-left blue" v-if="riskMonitor.state === 0">
                                <i class="iconfont green">&#xe60a;</i>{{riskMonitor.msg}}
                            </span>
                            <span class="item-left blue" v-else>
                                <i class="iconfont green">&#xe60a;</i>检测完毕，暂未发现文章存在违规内容
                            </span>
                        </div>
                    </div>
                </div>
                <div class="monitor-content" :class="{current: monitorTab === 1}">
                    <el-table :data="originalTable" border style="width: 100%" height="260px" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}" :cell-style="{fontSize: '12px',color: '#333'}">
                        <el-table-column label="检测语句">
                            <template slot-scope="scope">
                                <p class="text-overflow">{{scope.row.Content}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="百度相似度" align="center">
                            <template slot-scope="scope">
                                <div style="display: flex; justify-content: center; align-items: center;">
                                    <el-tag :type="originType(scope.row.OriginalValue)">{{originLevel(scope.row.OriginalValue)}}</el-tag>
                                    <el-button type="primary" size="mini" style="margin-left: 10px;" @click="openBaidu(scope.row.Content)"><i class="iconfont">&#xe8cb;</i>查百度</el-button>
                                </div>
                            </template>
                        </el-table-column>
<!--                        <el-table-column label="360相似度" align="center">-->
<!--                            <template slot-scope="scope">-->
<!--                                {{scope.row.threeSix}}%-->
<!--                            </template>-->
<!--                        </el-table-column>-->
                    </el-table>
                </div>
                <div class="monitor-content" :class="{current: monitorTab === 2}">
                    <p class="line">标题：{{emotionMonitor.text}}</p>
                    <p class="line">
                        情感描述：<span v-if="emotionMonitor.items.length > 0 && emotionMonitor.items[0].sentiment === 0">消极</span>
                                 <span v-else-if="emotionMonitor.items.length > 0 && emotionMonitor.items[0].sentiment === 1">中性</span>
                                 <span v-else-if="emotionMonitor.items.length > 0 && emotionMonitor.items[0].sentiment === 2">积极</span>
                    </p>
                    <div class="monitor-list">
                        <div class="item">
                            <span class="item-left blue">
                                <i class="iconfont green">&#xe60a;</i>
                                {{riskMonitor.msg}}，含有违禁词：
                                <span v-if="riskMonitor.state === 0">有</span>
                                <span v-else>无</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <div class="preview-shadow" v-if="showPreviewDialog"></div>
        <div class="preview-dialog" v-if="showPreviewDialog">
            <div class="preview-dialog-wrapper">
                <i class="iconfont close-preview" @click="previewArticle">&#xe623;</i>
                <div class="preview-box">
                    <div class="preview-title">{{previewArticleVersion.title}}</div>
                    <el-scrollbar class="preview-content" :native="false">
                        <div class="content" v-html="previewArticleVersion.content"></div>
                    </el-scrollbar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WE from "../../../utils/wEditor";
    import Clipboard from 'clipboard';
    import {articleSearch, hotArticles} from "../../../utils/apis";

    export default {
        data() {
            return {
                // 左侧
                showDraftBox: false, //显示草稿box的
                draftList: [],
                captionTabCurrent: 1,
                keysForm: {
                    keyOne: '',
                    keyTwo: '',
                    keyThree: '',
                },
                keysRules: {
                    keyOne: [
                        { required: true, message: '请输入关键词', trigger: 'blur' },
                        { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ],
                    keyTwo: [
                        { required: true, message: '请输入关键词', trigger: 'blur' },
                        { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ],
                    keyThree: [
                        { message: '请输入关键词', trigger: 'blur' },
                        { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ],
                },
                captionList: [
                    {
                        caption: '周扬青发文里面罗志祥的那些好友，盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁？'
                    },
                    {
                        caption: '周扬青发文里面罗志祥的那些好友，盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁？'
                    },
                    {
                        caption: '周扬青发文里面罗志祥的那些好友，盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁？'
                    },
                    {
                        caption: '周扬青发文里面罗志祥的那些好友，盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁？'
                    },
                    {
                        caption: '周扬青发文里面罗志祥的那些好友，盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁？'
                    },
                    {
                        caption: '周扬青发文里面罗志祥的那些好友，盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁盲猜都有谁？'
                    },
                ],
                hotCaptionList: [],
                captionSearch: '',

                // 中间
                createTitle: '',
                editorText: null,

                //中间七个按钮
                showDraftPopover: false, //草稿箱弹窗的显示
                draftBtnCurrent: false,
                seeDraftCurrent: false,
                showPublishPopover: false, //发布作品弹窗的显示
                detectionDialog: false, //检测弹窗
                detectionDetailDialog: false, //检测详情弹窗
                showCorrectPopover: false, //文章纠错弹窗
                correctBtnCurrent: false,
                correctContent: '',
                correctNum: 0,
                riskMonitor: { // 风险检测
                    state: null,
                    msg: '',
                    error_code: []
                },
                originalMonitor: { // 原创检测
                    status: null,
                    baidu_score: 0,
                    qihoo_score: 0,
                    score: 0,
                    text_num: 0,
                },
                emotionMonitor: { // 标题检测
                    log_id: null,
                    items: [],
                },
                field: '', // 文章领域
                monitorTab: 0, // 检测tabs
                originalTable: [],
                showPreviewDialog: false, // 预览弹窗
                previewArticleVersion: { // 预览文章
                    title: '',
                    content: null
                },

                // 右侧
                hotIconCurrent: false, //右侧box显示的
                hotTabCurrent: 1,
                hotContentCurrent: 0,
                baiduHotList: [],
                hotSpotTrackingInput: '',
                hotSpotList: []
            }
        },
        mounted() {
            this.editorText = new WE('#article_content', this.$api.art_uploadImg1,['head', 'bold', 'quote', 'underline', 'code', 'link', 'image', 'video'], function (html) {

            });
            this.getDraftList();
            this.searchHotCaption();
        },
        computed: {

        },
        watch: {
            // createTitle(newVal, oldVal) {
            //     console.log('newVal', newVal)
            //     console.log('oldVal', oldVal)
            //     if (this.getStrBytes(newVal) > 80) {
            //         this.createTitle = oldVal
            //         this.$message.warning('字符长度超过80个字符了')
            //     }
            // },
        },
        methods: {
            openBaidu(value) {
                const url = `https://www.baidu.com/s?tn=28114124_1_dg&wd=${value}`
                window.open(url, "_blank")
            },
            originType(value) {
                let type_value = "success"
                if (value === 'serious') {
                    type_value = "danger"
                }
                if (value === 'secondary') {
                    type_value = "warning"
                }
                return type_value
            },
            originLevel(value) {
                let desc_txt = ""
                switch (value) {
                    case 'low':
                        desc_txt = `少量重复`
                        break;
                    case 'secondary':
                        desc_txt = `中等重复`
                        break;
                    case 'serious':
                        desc_txt = `严重重复`
                        break;
                }
                return desc_txt;
            },
            // 中间获取文本长度
            getStrBytes(str, len=1) {
                let arr = [];
                let index = [];
                if (str === null || str === undefined) return 0;
                let total = 0;
                let charCode = '';
                for (let i = 0; i < str.length; i++) {
                    charCode = str.charCodeAt(i);
                    if (charCode <= 0x007f) {
                        total += 1;//字符代码在000000 – 00007F之间的，用一个字节编码
                    } else if (charCode <= 0x07ff) {
                        total += 2;//000080 – 0007FF之间的字符用两个字节
                    } else if (charCode <= 0xffff) {
                        total += 3;//000800 – 00D7FF 和 00E000 – 00FFFF之间的用三个字节，注: Unicode在范围 D800-DFFF 中不存在任何字符
                    } else {
                        total += 4;//010000 – 10FFFF之间的用4个字节
                    }
                    if (len === 1) continue;
                    let shang = Math.floor(total/len);
                    if (index.indexOf(shang) === -1) {
                        index.push(shang);
                        arr.push(i);
                    }
                }
                return arr.length === 0 ?  total : arr;
            },
            changeTitle(val) {
                let strLength = this.getStrBytes(val)
                let strTmp = this.getStrBytes(val, 80);
                let array = []
                for (let i = 0; i < strTmp.length; i++) {
                    if (strTmp[i+1]) {
                        let arr = [strTmp[i], strTmp[i+1]];
                        array.push(arr);
                    } else {
                        array.push([strTmp[i]]);
                    }
                }
                let substrArr = [];
                let content = val;
                for(let i = 0; i < array.length; i++) {
                    let contentTmp = content.substring(array[i][0], array[i][1]);
                    substrArr.push(contentTmp);
                }
                if (strLength > 80) {
                    this.createTitle = substrArr[0]
                    this.$message.warning('字符长度超过80个字符了')
                } else {
                    this.createTitle = val
                }
            },

            //左侧
            // 标签切换
            toggleCapTabs(index) {
                this.captionTabCurrent = index
            },
            // 随机生成
            randomlySubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 重置
            randomlyReset(formName) {
                this.$refs[formName].resetFields();
            },
            // 一键使用
            useCreate(item) {
                if (item.art_title.length > 26) {
                    this.createTitle = item.art_title.slice(0, 26)
                    this.$message.success('标题长度不超过26个字，已自动截取')
                } else {
                    this.createTitle = item.art_title
                    this.$message.success('一键使用成功')
                }
            },
            // 热门标题搜索
            searchHotCaption() {
                let params = {};
                if (this.captionSearch) {
                    params.title = this.captionSearch
                }
                hotArticles(params).then(res => {
                    this.hotCaptionList = res.data;
                }).catch(err => {
                    console.log(err);
                })
            },
            // 关闭草稿箱提示
            closeDraftPrompt() {
                this.showDraftBox = !this.showDraftBox
                if (this.seeDraftCurrent === false) {
                    this.draftBtnCurrent = true
                    this.seeDraftCurrent = true
                } else {
                    this.draftBtnCurrent = false
                    this.seeDraftCurrent = false
                }
            },
            // 获取草稿箱列表
            getDraftList() {
                this.$httpStudent.axiosGet(this.$api.dratfsList, (res) => {
                    if (res.code === 200) {
                        this.draftList = res.list
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 一键导入草稿
            importOne(item) {
                this.createTitle = item.title
                this.editorText.setWEHtml(item.content)
                this.$message.success('导入成功')
            },
            // 删除草稿
            delDraft(id) {
                this.$confirm('此操作将永久删除该草稿, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        id: id
                    }
                    this.$httpStudent.axiosGetBy(this.$api.delDratfs, params, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getDraftList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },

            // 中间七个按钮
            // 清空全文
            resetGraphic() {
                // this.createTitle = '';
                let text = '<p><br></p>';
                this.editorText.setWEHtml(text)
            },
            // 草稿箱弹窗显示
            showDraftCurrent() {
                this.draftBtnCurrent = true
            },
            // 草稿箱弹窗关闭
            hideDraftCurrent() {
                // this.draftBtnCurrent = false
                if (this.seeDraftCurrent) {
                    this.draftBtnCurrent = true
                } else {
                    this.draftBtnCurrent = false
                }
            },
            // 查看草稿箱
            seeDraft() {
                this.showDraftBox = !this.showDraftBox;
                this.showDraftPopover = false
                // this.seeDraftCurrent = !this.seeDraftCurrent
                if (this.seeDraftCurrent === false) {
                    this.draftBtnCurrent = true
                    this.seeDraftCurrent = true
                } else {
                    this.draftBtnCurrent = false
                    this.seeDraftCurrent = false
                }
            },
            // 存入草稿箱
            submitGraphic() {
                this.showDraftPopover = false
                if (this.createTitle === '') {
                    this.$message.warning('请输入标题')
                    return
                }
                if (this.editorText.getWEHtml() === '<p><br></p>') {
                    this.$message.warning('请输入内容')
                    return
                }
                let formData = new FormData();
                formData.append('title', this.createTitle);
                formData.append('content', this.editorText.getWEHtml());
                this.$httpStudent.axiosPost(this.$api.addDratfs, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.createTitle = '';
                        let text = '<p><br></p>';
                        this.editorText.setWEHtml(text)
                        this.getDraftList()
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 纠错弹窗显示
            showCorrectCurrent() {
                if (this.editorText.getWEText() === '') {
                    this.showCorrectPopover = false
                    return
                }
                this.correctBtnCurrent = true
            },
            // 纠错弹窗关闭
            hideCorrectCurrent() {
                this.correctBtnCurrent = false
                this.correctContent = ''
                this.correctNum = 0
            },
            // 文章纠错
            articleCorrect() {
                if (this.editorText.getWEText() === '') {
                    this.$message.warning('请输入内容')
                    return
                }
                this.correctBtnCurrent = true
                let strArr = this.getStrBytes(this.editorText.getWEText(), 500);
                let array = [];
                for (let i=0; i<strArr.length; i++) {
                    if (strArr[i+1]) {
                        let arr = [strArr[i], strArr[i+1]];
                        array.push(arr);
                    } else {
                        array.push([strArr[i]]);
                    }

                }
                let substrArr = [];
                let content = this.editorText.getWEText();
                for(let i=0; i<array.length; i++) {
                    let contentTmp = content.substring(array[i][0], array[i][1]);
                    substrArr.push(contentTmp);
                }
                let setIntervalEvent = setInterval(() => {
                    if (substrArr.length) {
                        let txt = substrArr.shift();
                        let text = null
                        let params = {
                            text: txt
                        }
                        this.$httpStudent.aPost(this.$api.errorChecking, params, (res) => {
                            if (res.code === 200) {
                                text = txt;
                                if (res.info.vec_fragment.length) {
                                    res.info.vec_fragment.map((item) => {
                                        let txt = `<div class="replace-content">
                                                <span class="text">${item.ori_frag}</span>
                                                <div class="replace-dialog">
                                                    <span class="dialog-text">${item.correct_frag}</span>
                                                </div>
                                            </div>`;
                                        let pattern = new RegExp(`${item.ori_frag}`, 'g');
                                        text = text.replace(pattern, txt);
                                    });
                                }
                                this.correctContent += text
                                this.correctNum += res.info.vec_fragment.length
                            } else {
                                this.$message.error(res.msg)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                        this.correctContent = ''
                        this.correctNum = 0
                    } else {
                        clearInterval(setIntervalEvent);
                    }
                }, 100); // 1000
                // this.correctContent = ''
                // this.correctNum = 0
            },
            // 关闭文章纠错弹窗
            closeCorrectPopover() {
                this.showCorrectPopover = false
            },
            // 质量分析
            qualityAnalysis() {
                if (this.createTitle === '') {
                    this.$message.warning('请输入标题')
                    return
                }
                if (this.editorText.getWEText() === '') {
                    this.$message.warning('请输入内容')
                    return
                }
                this.$httpStudent.axiosPost(this.$api.detectText, {text: this.editorText.getWEText()}, (res) => {
                    if (res.code === 200) {
                        this.riskMonitor = {
                            state: res.state,
                            msg: res.msg,
                        }
                        if (res.error_code !== void 0 && res.error_code.length > 0) {
                            this.riskMonitor.error_code = res.error_code
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
                this.$httpStudent.axiosPost(this.$api.originalCheck, {content: this.editorText.getWEText()}, (res) => {
                    if (res.code === 200) {
                        console.log("res.data", res.data)
                        this.originalMonitor.status = 1;
                        this.originalTable = res.data;
                        // this.originalMonitor = {
                        //     status: res.data.status,
                        //     baidu_score: res.data.baidu_score,
                        //     qihoo_score: res.data.qihoo_score,
                        //     score: res.data.score,
                        //     text_num: res.data.text_num
                        // }
                        // this.originalTable.push({
                        //     content: this.editorText.getWEText(),
                        //     baidu: res.data.baidu_score,
                        //     threeSix: res.data.qihoo_score
                        // })
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
                this.$httpStudent.axiosPost(this.$api.sentimentClassify, {text: this.createTitle}, (res) => {
                    if (res.code === 200) {
                        this.emotionMonitor = res.info
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
                let params = {
                    title: this.createTitle,
                    content: this.editorText.getWEText()
                }
                this.$httpStudent.axiosPost(this.$api.artTopic, params, (res) => {
                    if (res.code === 200) {
                        this.field = res.info[0].tag
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
                this.detectionDialog = true
            },
            // 质量分析下一步
            toNextDetection() {
                this.detectionDialog = false
                this.detectionDetailDialog = true
            },
            closeDetectionDetail() {
                this.riskMonitor = { // 风险检测
                    state: null,
                    msg: '',
                    error_code: []
                }
                this.originalMonitor = { // 原创检测
                    status: null,
                    baidu_score: 0,
                    qihoo_score: 0,
                    score: 0,
                    text_num: 0,
                }
                this.originalTable = []
                this.emotionMonitor = { // 标题检测
                    log_id: null,
                    items: [],
                }
                this.field = ''
                this.monitorTab = 0
            },
            // 风险检测tab切换
            toggleMonitor(index) {
                this.monitorTab = index
            },
            // 文章预览
            previewArticle() {
                if (this.createTitle === '') {
                    this.$message.warning('请输入标题')
                    return
                }
                if (this.editorText.getWEHtml() === '<p><br></p>') {
                    this.$message.warning('请输入内容')
                    return
                }
                this.previewArticleVersion = {
                    title: this.createTitle,
                    content: this.editorText.getWEHtml()
                };
                this.showPreviewDialog = !this.showPreviewDialog
            },
            // showPublishBtn() {
            //     if (this.createTitle === '') {
            //         return this.$message.warning('请输入标题')
            //     }
            //     if (this.editorText.getWEText() === '') {
            //         return this.$message.warning('请输入内容')
            //     }
            //     this.showPublishPopover = true
            // },
            // 发布作品
            publishBtn(type) {
                if (this.createTitle === '') {
                    return this.$message.warning('请输入标题')
                }
                if (this.editorText.getWEHtml() === '') {
                    return this.$message.warning('请输入内容')
                }
                if (type === 'bjh') {
                    let formData = new FormData()
                    formData.append('title', this.createTitle)
                    formData.append('content', this.editorText.getWEHtml())
                    this.$httpStudent.axiosPost(this.$api.api_issueBjHArticle, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message)
                            this.createTitle = '';
                            let text = '<p><br></p>';
                            this.editorText.setWEHtml(text)
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            },
            // 复制全文
            copyArticle(e) {
                if (this.editorText.getWEText() === '') {
                    this.$message.warning('请输入内容')
                    return
                }
                let copyContent = this.editorText.getWEText()
                const clipboard = new Clipboard(e.target, {text: () => copyContent})
                clipboard.on('success', e => {
                    this.$message({type: 'success', message: '复制成功'})
                    clipboard.off('error')
                    clipboard.off('success')
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    this.$message({type: 'waning', message: '该浏览器不支持自动复制'})
                    clipboard.off('error')
                    clipboard.off('success')
                    clipboard.destroy()
                })
                clipboard.onClick(e)
            },

            //右侧
            //右侧tab切换
            toggleHotTab(index) {
                this.hotTabCurrent = index
                this.hotContentCurrent = index
                this.hotIconCurrent = true
                Number(index) === 1 ? this.getBaiduHotList() : this.searchHotSpot();
            },
            //小箭头翻转
            toggleHotIcon(icon, tab) {
                this.hotIconCurrent = !this.hotIconCurrent
                this.hotTabCurrent = tab
                if (this.hotIconCurrent === true) {
                    if (tab === 1) {
                        this.hotContentCurrent = 1
                        this.getBaiduHotList()
                    } else if (tab === 2) {
                        this.hotContentCurrent = 2
                        // this.getBaiduHotList();
                        this.searchHotSpot()
                    }
                } else {
                    this.hotContentCurrent = 0
                }
            },
            //百度热搜 list
            getBaiduHotList() {
                articleSearch({type: 1}).then(res => {
                    if (res.code === 200) {
                        this.baiduHotList = res.data.list;
                    } else {
                        this.$message.warning(res.msg);
                    }
                }).catch(err => {
                    console.log('百度热搜请求异常',err);
                });
                /* this.$httpStudent.axiosGet(this.$api.baiduHotList, (res) => {
                    if (res.code === 200) {
                        this.baiduHotList = res.info
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                }) */
            },
            //热点追踪list
            searchHotSpot() {
                let param = {type: 0}
                if (this.hotSpotTrackingInput) {
                    param.content = this.hotSpotTrackingInput.trim();
                }
                articleSearch(param).then(res => {
                    if (res.code === 200) {
                        if (res.data.url) {
                            window.open(res.data.url, "_blank");
                            this.hotSpotTrackingInput = "";
                            return;
                        }
                        console.log('12345',res.data.list);
                        this.hotSpotList = res.data.list;
                    } else {
                        this.$message.waring(res.msg);
                    }
                }).catch(err => {
                    console.log('热点追踪异常', err);
                })
                /* let params = {};
                if (this.hotSpotTrackingInput === '') {
                    params.content = '新冠肺炎'
                } else {
                    params.content = this.hotSpotTrackingInput
                }
                this.$httpStudent.axiosGetBy(this.$api.searchNew, params, (res) => {
                    if (res.code === 200) {
                        console.log('res',res.data);
                        this.hotSpotList = res.data
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                }) */
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep p {
        margin: 0;
    }
    .search-text-ellipsis {
        &::v-deep em {
            color: #f04142;
            font-style: normal;
        }
    }
    .graphic-container {
        position: relative;
        /*overflow: hidden;*/
        display: flex;
        /*align-items: center;*/
        flex-direction: column;
        height: calc(100vh - 100px);
        margin-right: -20px;
        .tab-wrapper {
            margin: 27px 0 17px;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                background: #DFDCFF;
            }
            .tab-item {
                display: inline-block;
                height: 32px;
                line-height: 32px;
                padding: 0 22px;
                color: #574DED;
                border-radius: 4px;
                font-size: 16px;
                margin-right: 20px;
                transition: all .3s;
                &.current, &:hover {
                    color: #fff;
                    background: #574DED;
                }
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 40px;
                }
            }
        }
        .graphic-box {
            flex: 1;
            height: 1%;
            display: flex;
            align-items: center;
        }
        .graphic-wrapper {
            /*width: calc(100% - 552px);*/
            height: 100%;
            display: flex;
            width: 1%;
            flex: 1;
            .caption-wrapper {
                width: 35%;
                height: 100%;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
                .caption-tabs {
                    background: #fff;
                    text-align: center;
                    height: 52px;
                    line-height: 52px;
                    position: relative;
                    border-radius: 10px 10px 0 0;
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 1px;
                        background: #EEE;
                    }
                    .tab-item {
                        display: inline-block;
                        margin-right: 60px;
                        position: relative;
                        cursor: pointer;
                        transition: all .3s;
                        &:last-child {
                            margin-right: 0;
                        }
                        &.current {
                            color: #1E33E4;
                            &:after {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: 2px;
                                background: #1E33E4;
                            }
                        }
                        &:hover {
                            color: #1E33E4;
                        }
                    }
                }
                .caption-item {
                    background: #fff;
                    /*margin-top: 16px;*/
                    display: none;
                    height: 1%;
                    flex: 1;
                    flex-direction: column;
                    border-radius: 0 0 10px 10px;
                    &.current {
                        display: flex;
                    }
                    .caption-list {
                        height: 1%;
                        flex: 1;
                        margin: 10px 0;
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                        .item {
                            padding: 16px;
                            border-bottom: 1px solid #EAEAEA;
                            .caption {
                                margin-bottom: 10px;
                                color: #333;
                                &:hover {
                                    color: #1E33E3;
                                }
                            }
                            .caption-btn {
                                text-align: right;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
                &.draft-wrapper {
                    background: #fff;
                    .draft-top {
                        height: 40px;
                        line-height: 40px;
                        background: #E5E8FB;
                        display: flex;
                        border-radius: 10px 10px 0 0;
                        & > span {
                            width: 1%;
                            flex: 1;
                            padding-left: 20px;
                        }
                        .el-button {
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .draft-list {
                        height: 1%;
                        flex: 1;
                        margin: 10px 0;
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                        .draft-item {
                            padding: 16px 20px;
                            border-bottom: 1px solid #EAEAEA;
                            .title {
                                font-size: 16px;
                                color: #333;
                            }
                            .content {
                                color: #999;
                                margin-top: 10px;
                                max-height: 38px;
                                ::v-deep img {
                                    display: none;
                                }
                                ::v-deep iframe {
                                    display: none;
                                }
                                ::v-deep * {
                                    font-size: 14px;
                                    font-weight: normal!important;
                                    text-decoration-line: none!important;
                                    background-color: transparent!important;
                                    color: #999;
                                    margin: 0;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                }
                            }
                            .btn-group {
                                margin-top: 10px;
                                text-align: right;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .publish-wrapper {
                background: #fff;
                margin-left: 40px;
                width: 1%;
                flex: 1;
                height: 100%;
                padding: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
                border-radius: 10px;
                .create-title {
                    display: flex;
                    .text {
                        line-height: 32px;
                    }
                    .el-input {
                        margin-left: 10px;
                        width: 1%;
                        flex: 1;
                    }
                }
                #article_content {
                    margin-top: 12px;
                    height: 1%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    z-index: 1;
                    ::v-deep .w-e-toolbar {
                        padding: 0;
                        border: none!important;
                        background-color: initial!important;
                    }
                    ::v-deep .w-e-text-container {
                        border: none!important;
                        height: 1%;
                        flex: 1;
                        .w-e-text {
                            padding: 0;
                            overflow-y: auto;
                        }
                    }
                }
            }
        }
        .hot-search-wrapper {
            /*margin-left: 70px;*/
            /*position: absolute;*/
            /*top: 20px;*/
            //right: -360px;
            /*right: 0;*/
            /*bottom: 20px;*/
            transition: all .3s;
            position: relative;
            height: 100%;
            .hot-tab-wrapper {
                position: absolute;
                width: 30px;
                left: -30px;
                top: 28px;
                text-align: center;
                .left-icon {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    background: #E5E8FB;
                    color: #666;
                    cursor: pointer;
                    &.current {
                        transform: rotate(180deg);
                    }
                }
                .hot-tabs {
                    background: #fff;
                    line-height: 30px;
                    writing-mode: vertical-lr;
                    height: 90px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    &.current {
                        color: #fff;
                        background: #2338E6;
                    }
                }
            }
            .hot-search-content {
                width: 360px;
                height: 100%;
                display: none;
                transition: all .3s;
                background: #fff;
                padding-top: 28px;
                box-sizing: border-box;
                border-radius: 10px 0 0 10px;
                box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
                &.current {
                    display: block;
                }
                .hot-search-prompt {
                    color: #999;
                    font-size: 12px;
                    background: #E5E8FB;
                    height: 30px;
                    line-height: 30px;
                    padding-left: 16px;
                }
                .hot-search-list {
                    height: calc(100% - 30px);
                    display: flex;
                    flex-direction: column;
                    background: #fff;
                    border-radius: 0 0 0 10px;
                    .list-wrapper {
                        height: 1%;
                        flex: 1;
                        padding: 12px 0;
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                    }
                    .item {
                        display: flex;
                        align-items: center;
                        line-height: 36px;
                        &.item-top {
                            height: 44px;
                            line-height: 44px;
                            border-bottom: 1px solid #EAEAEA;
                        }
                        &.item-hotspot {
                            height: 44px;
                            padding: 0 16px;
                            border-bottom: 1px solid #EAEAEA;
                            .el-button {
                                margin-left: 10px;
                            }
                        }
                        .item-left {
                            width: 1%;
                            flex: 1;
                            padding-left: 16px;
                            display: flex;
                            align-items: center;
                            .item-index {
                                width: 20px;
                                height: 20px;
                                background: #8EB9F5;
                                border-radius: 50%;
                                color: #fff;
                                text-align: center;
                                line-height: 20px;
                            }
                            .item-cap {
                                width: 1%;
                                flex: 1;
                                margin-left: 10px;
                                cursor: pointer;
                                transition: all .3s;
                                color: #333;
                                &:hover {
                                    color: #1E34E4;
                                }
                            }
                        }
                        &:nth-child(1) {
                            .item-left {
                                .item-index {
                                    background: #FF0000;
                                }
                            }
                        }
                        &:nth-child(2) {
                            .item-left {
                                .item-index {
                                    background: #FF8547;
                                }
                            }
                        }
                        &:nth-child(3) {
                            .item-left {
                                .item-index {
                                    background: #FFAC38;
                                }
                            }
                        }
                        .item-right {
                            padding-right: 24px;
                            margin-left: 10px;
                            .item-num {
                                font-size: 12px;
                                color: #999;
                            }
                            .iconfont {
                                font-size: 12px;
                                margin-left: 3px;
                                font-weight: bold;
                                &.up-icon {
                                    color: #FF3333;
                                }
                                &.down-icon {
                                    color: #2338E6;
                                }
                            }
                        }
                    }
                }
            }
        }
        .operate-btn {
            /*position: absolute;*/
            /*top: 50%;*/
            //transform: translateY(-50%);
            /*left: calc(100% - 536px);*/
            margin: 0 80px 0 20px;
            .operate-item {
                line-height: 36px;
                background: #fff;
                margin-top: 10px;
                width: 102px;
                padding-left: 12px;
                box-sizing: border-box;
                cursor: pointer;
                transition: all .3s;
                border-radius: 2px;
                .iconfont {
                    font-size: 16px;
                    margin-right: 6px;
                    vertical-align: bottom;
                }
                &.current, &:hover {
                    color: #fff;
                    background: #2338E6;
                }
            }
        }
    }
    .keys-form {
        padding: 16px;
        border-bottom: 1px solid #EAEAEA;
        .prompt-info {
            color: #999;
            margin-bottom: 20px;
        }
        .el-form-item {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .hot-caption {
        padding: 16px;
        border-bottom: 1px solid #EAEAEA;
        .hot-caption-search {
            display: flex;
            .text {
                line-height: 32px;
            }
            .el-input {
                width: 1%;
                flex: 1;
                margin-left: 10px;
            }
        }
        .hot-cap {
            margin-top: 16px;
        }
    }
    .draftPopover {
        .draft-box {
            padding: 4px;
            .draft-btn {
                color: #333;
                cursor: pointer;
                padding: 10px 18px;
                position: relative;
                border-bottom: 1px solid #EAEAEA;
                border-radius: 2px;
                &:hover {
                    color: #2338E6;
                }
                &:last-child {
                    border-bottom: none;
                }
                &.current {
                    color: #fff;
                    background: #2338E6;
                }
                &.publish-btn {
                    display: flex;
                    align-items: center;
                    img {
                        max-width: 24px;
                        max-height: 24px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .detection-dialog {
        ::v-deep .el-dialog__footer {
            text-align: center;
        }
        .detection-content {
            .item {
                font-size: 16px;
                display: flex;
                margin-top: 10px;
                .item-left {
                    width: 1%;
                    flex: 1;
                }
                .item-right {
                    width: 150px;
                    text-align: left;
                    .iconfont {
                        margin-right: 10px;
                        &.green {
                            color: #00C83C;
                        }
                        &.red {
                            color: #ff0000;
                        }
                    }
                }
                .turn-round {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    animation: turnRound 1s linear infinite;
                    margin-right: 10px;
                }
            }
        }
    }
    .detection-detail-dialog {
        ::v-deep .el-dialog__body {
            padding: 20px 0;
            border-top: 1px solid #EAEAEA;
            .detection-detail-wrapper {
                .detail-intro {
                    padding: 0 20px;
                    .intro-item {
                        margin-top: 16px;
                        &:first-child {
                            margin-top: 0;
                        }
                        span {
                            margin-right: 20px;
                            &.item-right {
                                strong {
                                    color: #FF0000;
                                    margin-right: 20px;
                                }
                                .blue {
                                    color: #1E33E3;
                                }
                            }
                        }
                    }
                }
                .monitor-tabs {
                    margin-top: 20px;
                    background: #F5F5F5;
                    text-align: center;
                    .tab-item {
                        display: inline-block;
                        line-height: 36px;
                        padding: 0 24px;
                        cursor: pointer;
                        &.current {
                            color: #fff;
                            background: #2338E6;
                        }
                    }
                }
                .monitor-content {
                    display: none;
                    height: 260px;
                    margin: 20px 20px 0;
                    border: 1px solid #EAEAEA;
                    padding: 20px;
                    overflow: auto;
                    &.current {
                        display: block;
                    }
                    .monitor-list {
                        .item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 10px;
                            .item-left {
                                .iconfont {
                                    margin-right: 10px;
                                }
                                &.blue {
                                    font-size: 16px;
                                }
                            }
                            .blue {
                                color: #1E33E3;
                            }
                            .green {
                                color: #00C83C;
                            }
                            .red {
                                color: #FF0000;
                            }
                        }
                    }
                    .line {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .correctPopover {
        .correct-box {
            display: flex;
            flex-direction: column;
            min-height: 300px;
            max-height: 500px;
            .correct-title {
                padding-left: 20px;
                color: #666;
                background: #E5E8FB;
                line-height: 40px;
                position: relative;
                .el-button {
                    position: absolute;
                    right: 0;
                    width: 40px;
                }
            }
            .correct-content {
                padding: 20px;
                height: 1%;
                flex: 1;
                overflow: auto;
                margin-bottom: 40px;
            }
            .correct-bottom {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                line-height: 40px;
                color: #666;
                background: #E5E8FB;
                padding-left: 20px;
                strong {
                    color: #ff851a;
                }
            }
            ::v-deep .replace-content {
                position: relative;
                display: inline-block;
                &:hover .replace-dialog {
                    display: block;
                }
                .text {
                    background: #bad7fb;
                    z-index: 6;
                }
                .replace-dialog {
                    z-index: 7;
                    position: absolute;
                    top: 25px;
                    left: 50%;
                    min-width: 12px;
                    white-space: nowrap;
                    background: #303133;
                    color: #fff;
                    border-radius: 4px;
                    padding: 10px;
                    transform: translateX(-50%);
                    font-size: 12px;
                    line-height: 1.2;
                    display: none;
                    &:before {
                        content: '';
                        position: absolute;
                        top: -6px;
                        left: 50%;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 6px;
                        border-top-width: 0;
                        border-bottom-color: #303133;
                        transform: translateX(-50%);
                        background: #ff0000;
                    }
                }
            }
        }
    }
    @keyframes turnRound {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .preview-shadow {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 9;
    }
    .preview-dialog {
        width: 346px;
        height: 693px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 11;
        &:before {
            content: '';
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            z-index: 9;
            background: #fff;
            border-radius: 30px;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent url("../../../assets/img/image/phone-bg.png") no-repeat;
            z-index: 9;
        }
        .preview-dialog-wrapper {
            position: absolute;
            top: 4px;
            left: 10px;
            right: 10px;
            bottom: 4px;
            border-radius: 75px;
            padding: 41px 11px 15px 13px;
            z-index: 10;
            .close-preview {
                position: absolute;
                right: -50px;
                top: 0;
                font-size: 18px;
                color: #fff;
                cursor: pointer;
            }
            .preview-box {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                .preview-title {
                    margin: 20px 0;
                    padding: 0 20px;
                    font-size: 18px;
                    color: #333;
                    font-weight: bold;
                }
                .preview-content {
                    flex: 1;
                    height: 1%;
                    ::v-deep img {
                        max-width: 100%;
                    }
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    ::v-deep iframe {
                        max-width: 100%;
                    }
                    ::v-deep pre {
                        overflow: auto;
                    }
                    ::v-deep a {
                        color: #1E33E3;
                    }
                    ::v-deep blockquote {
                        display: block;
                        border-left: 8px solid #d0e5f2;
                        padding: 5px 10px;
                        margin: 10px 0;
                        line-height: 1.4;
                        font-size: 100%;
                        background-color: #f1f1f1;
                    }
                    ::v-deep code {
                        background-color: #f1f1f1;
                        border-radius: 3px;
                        padding: 3px 5px;
                        margin: 0 3px;
                    }
                    .content {
                        padding: 0 20px 20px;
                    }
                }
            }
        }
    }
</style>
